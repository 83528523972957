import { useEffect } from "react";

import AOS from "aos";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { ThemeProvider, useTheme } from "next-themes";

import "../styles/globals.scss";

import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "highlight.js/styles/monokai-sublime.css";

import InitAnalytics from "../components-protocol-v4/InitAnalytics/InitAnalytics";
import * as gtag from "../lib/gtag";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const AnyComponent = Component as any;

  // if (router.asPath !== '/') {
  //   return (
  //     <ThemeProvider defaultTheme="dark" disableTransitionOnChange={true}>
  //       <AnyComponent {...pageProps} />
  //     </ThemeProvider>
  //   );
  // }

  return (
    <ThemeProvider defaultTheme="light" disableTransitionOnChange={true}>
      <InitAnalytics />
      <AnyComponent {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
