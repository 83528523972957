"use client";

import { useEffect } from "react";

import * as amplitude from "@amplitude/analytics-browser";

const InitAnalytics = () => {
  useEffect(() => {
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!);
  }, []);

  return null;
};

export default InitAnalytics;
